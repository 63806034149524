import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonAvatar,
  IonLabel,
  IonItem,
  IonProgressBar
} from '@ionic/react';
import React, { useState } from 'react';
import './MeTab.css';
import moment from 'moment';
import { logoLinkedin, logoTwitter, logoInstagram, musicalNotesOutline, gameControllerOutline, videocamOutline, basketballOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../LanguageSelector';
import MailFab from '../../components/MailFab';
import { goToTop } from '../../utils';
import ModeSelector from '../../ModeSelector';

const MeTabPage: React.FC = () => {

  // MM/dd/YYYY
  const dob = new Date("12/12/1992");
  const ageCalculated = () => moment().diff(dob, "years");
  
  const [age] = useState(ageCalculated);
  // const [scroll, setScroll] = useState(false);
  const { t } = useTranslation();
  const [fabCssClass, setFabCssClass] = useState("extended");
  
  const handleScroll = (e: any) => {
    setFabCssClass(e.detail.currentY < 50 ? "extended" : "minimized")
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle><div className="clickable" onClick={() => goToTop("me-content")}>{t("meTabPageTitle")}</div></IonTitle>
          <ModeSelector/>
          <IonButtons slot="end">
            <LanguageSelector />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent id="me-content" scrollEvents={true} onIonScroll={(e: any) => handleScroll(e)}>
        <MailFab cssClass={fabCssClass} />
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-lg="8" offset-lg="2" size-xl="6" offset-xl="3">
              <IonCard>
                <IonCardHeader>
                  <IonAvatar id="me-avatar">
                    <img src="/assets/me.jpeg" alt="me" />
                  </IonAvatar>
                  <IonCardTitle>Julien TELA ({age} {t("yearsOld")})</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <p dangerouslySetInnerHTML={ { __html: t("meDescription") } } className="text-justify">
                  </p>
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardContent>
                  <IonGrid>
                    <IonRow>
                      <IonCol align-self-center>
                        <IonButton expand="full" fill="clear" target="_blank" rel="noopener noreferrer" href="https://fr.linkedin.com/in/julien-tela-0835a060">
                          <IonIcon className="linkedin" icon={logoLinkedin} />
                        </IonButton>
                      </IonCol>
                      <IonCol>
                        <IonButton expand="full" fill="clear" target="_blank" rel="noopener noreferrer" href="https://twitter.com/JulienTela?s=09">
                          <IonIcon className="twitter" icon={logoTwitter} />
                        </IonButton>
                      </IonCol>
                      <IonCol>
                        <IonButton expand="full" fill="clear" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/guylers/">
                          <IonIcon className="instagram" icon={logoInstagram} />
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  <IonButton color="tertiary" expand="full" routerLink="/me/wtfm">{t("whereToFindMe")}</IonButton>
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>{t("languages")}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <span role="img" aria-label="fr-flag">🇫🇷</span> <IonProgressBar value={1}/>
                  <span role="img" aria-label="uk-flag">🇬🇧</span> <IonProgressBar value={0.90} buffer={0.95}/>
                  <span role="img" aria-label="sp-flag">🇪🇸</span> <IonProgressBar value={0.50} buffer={0.50}/>
                  <span role="img" aria-label="ru-flag">🇷🇺</span> <IonProgressBar value={0.10} buffer={0.15}/>
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>{t("hobbies")}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonItem>
                          <IonIcon icon={basketballOutline} />
                          <IonLabel>
                            {t("sport")}
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                      <IonCol>
                        <IonItem>
                          <IonIcon icon={musicalNotesOutline} />
                          <IonLabel>
                            {t("music")}
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <IonItem>
                          <IonIcon icon={gameControllerOutline} />
                          <IonLabel>
                            {t("esport")}
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                      <IonCol>
                        <IonItem>
                          <IonIcon icon={videocamOutline} />
                          <IonButton color="tertiary" expand="full" size="small" routerLink="/me/movies">
                            {t("cinema")}
                          </IonButton>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default MeTabPage;
