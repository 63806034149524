import React, { Component } from 'react';
import axios from 'axios';
import { IonCard, IonCardTitle, IonCardContent, IonCardHeader, IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonContent, IonCardSubtitle, IonLabel, IonGrid, IonRow, IonCol, IonThumbnail, IonSkeletonText } from '@ionic/react';
import i18next from 'i18next';
import LanguageSelector from '../LanguageSelector';
import { goToTop } from '../utils';

type Movie = {
    title: string,
    year: string,
    plot: string,
    poster: string,
    actors: string,
    director: string
}
type MovieListState = {

    items: Movie[],
    busy: boolean
}

export class MovieList extends Component<{}, MovieListState> {

    url = 'https://www.omdbapi.com/';
    apiKey = "?apikey=2be398c0&";
    movieTitles = ["the prestige", "jaws", "the empire strikes back", "The King's Speech", "amélie", "the artist"];
    moviesFetched: Array<Movie> = [];
    state = {
        items: this.moviesFetched,
        busy: true
    }

    async componentDidMount() {
        this.movieTitles.forEach(title => {
            axios.get(`${this.url}${this.apiKey}t=${title}`).then(response => response.data)
                .then((data) => {
                    this.moviesFetched.push({
                        title: data["Title"],
                        year: data["Year"],
                        actors: data["Actors"],
                        director: data["Director"],
                        plot: data["Plot"],
                        poster: data["Poster"]
                    });
                    return this.moviesFetched
                }
                ).then(() => {
                    this.setState({ items: this.moviesFetched, busy: false });
                })
        })
    }
    /**
    * // TODO: use translation tool to translate movie texts
    * 
    */
    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar color="primary">
                        <IonButtons slot="start">
                            <IonBackButton text={""} defaultHref="/me" />
                        </IonButtons>
                        <IonTitle><div className="clickable" onClick={() => goToTop("movie-list-content")}>{i18next.t("myFavoriteMoviesPageTitle")}</div></IonTitle>
                        <IonButtons slot="end">
                            <LanguageSelector />
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent id="movie-list-content">
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12" size-lg="8" offset-lg="2" size-xl="6" offset-xl="3">
                                <IonCard>
                                    <IonCardContent>
                                        <IonLabel>{i18next.t("moviesDataOrigin")}</IonLabel>
                                    </IonCardContent>
                                </IonCard>
                                {this.state.busy ?
                                    <>
                                        {Array(5).fill(0).map((el, index) => (
                                            <IonCard key={index}>
                                                <div style={{ margin: " 0 auto", width: "30%", padding: "1%" }}>
                                                    <div style={{ margin: " 0 auto", width: "30%", padding: "1%" }}>
                                                        <IonThumbnail>
                                                            <IonSkeletonText animated />
                                                        </IonThumbnail>
                                                        <IonThumbnail>
                                                            <IonSkeletonText animated />
                                                        </IonThumbnail>
                                                        <IonThumbnail>
                                                            <IonSkeletonText animated />
                                                        </IonThumbnail>
                                                    </div>
                                                </div>
                                                <IonCardHeader>
                                                    <IonCardSubtitle><IonSkeletonText animated style={{ width: '50%' }} /></IonCardSubtitle>
                                                    <IonCardTitle><IonSkeletonText animated style={{ width: '30%' }} /></IonCardTitle>
                                                    <IonCardSubtitle><IonSkeletonText animated style={{ width: '50%' }} /></IonCardSubtitle>
                                                </IonCardHeader>
                                                <IonCardContent>
                                                    <IonSkeletonText animated />
                                                    <IonSkeletonText animated />
                                                    <IonSkeletonText animated />
                                                    <IonSkeletonText animated />
                                                </IonCardContent>
                                            </IonCard>
                                        ))}
                                    </>
                                    :
                                    <>
                                        {this.state.items.map((item, index) =>
                                            <IonCard key={index}>
                                                <div style={{ margin: " 0 auto", width: "30%", padding: "1%" }}>
                                                    <img src={item.poster} alt="" />
                                                </div>
                                                <IonCardHeader>
                                                    <IonCardSubtitle>{item.director}, {item.year}</IonCardSubtitle>
                                                    <IonCardTitle>{item.title}</IonCardTitle>
                                                    <IonCardSubtitle>{item.actors}</IonCardSubtitle>
                                                </IonCardHeader>
                                                <IonCardContent className="text-justify">
                                                    {item.plot}
                                                </IonCardContent>
                                            </IonCard>
                                        )
                                        }
                                    </>
                                }
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage>
        );
    }
}
export default MovieList;