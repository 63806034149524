import React from 'react';
import { IonBackButton, IonButtons, IonHeader, IonPage, IonToolbar, IonTitle, IonContent, IonCard, IonCardContent } from '@ionic/react';
import Map from '../Map';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../LanguageSelector';


const WhereToFindMe: React.FC = () => {
  const { t } = useTranslation();
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton text={""} defaultHref="/me" />
          </IonButtons>
          <IonTitle>{t("whereToFindMe")}</IonTitle>
          <IonButtons slot="end">
            <LanguageSelector  />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonCardContent>
            <Map />
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default WhereToFindMe;
