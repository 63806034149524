import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEnglish from './locales/en.json';
import translationFrench from './locales/fr.json';
import XHR from "i18next-xhr-backend";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translations: translationEnglish
  },
  fr: {
    translations: translationFrench
  }
};

i18n
  .use(XHR)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    ns: ["translations"],
    defaultNS: "translations",
    lng: "fr",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;