import React, { useState } from 'react';
import { IonHeader, IonToolbar, IonPage, IonTitle, IonContent, IonButtons } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../LanguageSelector';
import MailFab from '../../components/MailFab';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import esiagLogo from './esiag-logo.png';
import iutLogo from './iut-logo.png';
import lyceeLogo from './lycee-logo.png';
import paris1Logo from './paris1-logo.png';
import { goToTop } from '../../utils';
import ModeSelector from '../../ModeSelector';
import { useSelector } from 'react-redux';


const EducationTabPage: React.FC = () => {
  const { t } = useTranslation();
  const [fabCssClass, setFabCssClass] = useState("extended");
  let lightMode: boolean = useSelector((state: any) => state.lightMode);

  const handleScroll = (e: any) => {
    setFabCssClass(e.detail.currentY < 50 ? "extended" : "minimized")
  }
  let white = "#ffff";
  let color = lightMode ? white : getComputedStyle(document.documentElement)
  .getPropertyValue('--ion-color-dark');
  let lightColor = lightMode ? "light" : "";
  const style = {
    contentStyle : color,
    contentArrowStyle: `7px solid ${color}`
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle><div className="clickable" onClick={() => goToTop("education-content")}>{t("educationTabPageTitle")}</div></IonTitle>
          <ModeSelector/>
          <IonButtons slot="end">
            <LanguageSelector  />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent id="education-content" color={lightColor} scrollEvents={true} onIonScroll={(e: any) => handleScroll(e)}>
        <MailFab cssClass={fabCssClass} />
        <VerticalTimeline>
          <VerticalTimelineElement
            contentStyle={{ background: style.contentStyle }}
            contentArrowStyle={{ borderRight: style.contentArrowStyle }} 
            date="2014 - 2016"
            iconStyle={{ background: white}}
            icon={<img src={esiagLogo} alt="esiagLogo" />}
          >
            <h3 className="vertical-timeline-element-title">{t("master")}</h3>
            <h4 className="vertical-timeline-element-subtitle">{t("esiag")}</h4>
            <p>
              {t("inWorkStudy")}
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            contentStyle={{ background: style.contentStyle }}
            contentArrowStyle={{ borderRight: style.contentArrowStyle }}           
            date="2013 - 2014"
            iconStyle={{ background: white}}
            icon={<img src={esiagLogo} alt="esiagLogo" />}
          >
            <h3 className="vertical-timeline-element-title">{t("licence")}</h3>
            <h4 className="vertical-timeline-element-subtitle">{t("esiag")}</h4>
            <p>
              {t("inWorkStudy")}
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            contentStyle={{ background: style.contentStyle }}
            contentArrowStyle={{ borderRight: style.contentArrowStyle }}           
            date="2012 - 2013"
            iconStyle={{ background: white}}
            icon={<img src={paris1Logo} alt="paris1Logo" />}

          >
            <h3 className="vertical-timeline-element-title">{t("demography")}</h3>
            <h4 className="vertical-timeline-element-subtitle">{t("sorbonne")}</h4>
            <p>     </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            contentStyle={{ background: style.contentStyle }}
            contentArrowStyle={{ borderRight: style.contentArrowStyle }} 
            date="2010 - 2012"
            iconStyle={{ background: white}}
            icon={<img src={iutLogo} alt="iutLogo" />}

          >
            <h3 className="vertical-timeline-element-title">{t("dut")}</h3>
            <h4 className="vertical-timeline-element-subtitle">{t("descartes")}</h4>
            <p>    </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            contentStyle={{ background: style.contentStyle }}
            contentArrowStyle={{ borderRight: style.contentArrowStyle }} 
            date="2007 - 2010"
            iconStyle={{ background: white}}
            icon={<img src={lyceeLogo} alt="lyceeLogo" />}

          >
            <h3 className="vertical-timeline-element-title">{t("bac")}</h3>
            <h4 className="vertical-timeline-element-subtitle">{t("highschool")}</h4>
            <p>    </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: 'rgb(16, 204, 82)', color: white }}

          />
        </VerticalTimeline>
      </IonContent>
    </IonPage>
  );
};

export default EducationTabPage;
