import { IonFab, IonIcon, IonModal, IonButton, IonGrid, IonRow, IonCol, IonInput, IonItem, IonLabel, IonTextarea, IonContent, IonHeader, IonToolbar, IonTitle } from "@ionic/react";
import React, { useState } from "react";
import { mailOutline, trashOutline, sendOutline } from "ionicons/icons";
import emailjs from 'emailjs-com';
import { useTranslation } from "react-i18next";
import { toast } from "../toast";
import './MailFab.css'

interface Props {
    cssClass: string
}

const MailFab: React.FC<Props> = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [contactorName, setContactorName] = useState("");
    const [contactorMail, setContactorMail] = useState("");
    const [mailObject, setMailObject] = useState("");
    const [mailText, setMailText] = useState("");
    const [disableSendMail, setDisableSendMail] = useState(true);
    const { t } = useTranslation();
    let fabCssClass = props.cssClass

    const handleContactorNameChange = (event: any) => {
        setContactorName(event.target.value);
        check();
    }
    const handleContactorMailChange = (event: any) => {
        setContactorMail(event.target.value);
        check();
    }
    const handleMailObjectChange = (event: any) => {
        setMailObject(event.target.value);
        check();
    }
    const handleMailTextChange = (event: any) => {
        setMailText(event.target.value);
        check();
    }

    const check = () => {
        if (contactorName.length < 2 || contactorMail.length < 2
            || mailObject.length < 2 || mailText.length < 2) {
            setDisableSendMail(true);
        } else {
            setDisableSendMail(false);
        }
    }

    const handleSubmit = (event: any) => {
        const templateId = 'template_tEANG5X7';

        setDisableSendMail(true);

        sendMail(templateId,
            {
                message_html: mailText,
                from_name: contactorName,
                from_mail: contactorMail,
                mail_to: "developer.julien@gmail.com",
                mail_subject: mailObject,
            })
        setShowModal(false);
    }

    const sendMail = async (templateId: string, variables: any) => {
        let message = "";

        try {
            let res = await emailjs.send(
                'default_service', templateId,
                variables, 'user_klq4LNhasomaHlFfZncMr'
            );

            if (200 === res.status) {
                message = t("emailSentOK");
            } else {
                message = t("emailSentKO");
            }
        } catch (error) {
            message = t("emailSentKO");
        } finally {
            toast(message);
        }
    }

    const clear = () => {
        setContactorMail("");
        setContactorName("");
        setMailObject("");
        setMailText("");
    }

    return (
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonButton className={fabCssClass} shape="round" color="tertiary" onClick={() => setShowModal(true)}>
                <IonIcon icon={mailOutline} /><span>{t("contactMe")}</span>
            </IonButton>
            <IonModal isOpen={showModal} swipeToClose={true} onDidDismiss={() => setShowModal(false)}>
                <IonHeader>
                    <IonToolbar color="primary">
                        <IonTitle>{t("mailModalPageTitle")}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonItem>
                        <IonLabel position="floating">{t("contactorName")}</IonLabel>
                        <IonInput onIonChange={(event) => handleContactorNameChange(event)} value={contactorName}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">{t("contactorMail")}</IonLabel>
                        <IonInput onIonChange={(event) => handleContactorMailChange(event)} value={contactorMail}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">{t("mailObject")}</IonLabel>
                        <IonInput onIonChange={(event) => handleMailObjectChange(event)} value={mailObject}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">{t("mailText")}</IonLabel>
                        <IonTextarea rows={4} onIonChange={(event) => handleMailTextChange(event)} value={mailText}></IonTextarea>
                    </IonItem>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonButton color="tertiary" expand="full" onClick={() => setShowModal(false)}>
                                    {t("cancel")}
                                </IonButton>
                            </IonCol>
                            <IonCol>
                                <IonButton disabled={disableSendMail} expand="full" onClick={(event) => handleSubmit(event)}>
                                    <IonIcon icon={sendOutline} />
                                </IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonButton color="secondary" expand="full" onClick={clear}>
                                    <IonIcon icon={trashOutline}/>
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonModal>
        </IonFab>
    );
};

export default MailFab;