import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupConfig
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { laptopOutline, personOutline, gitBranchOutline, schoolOutline } from 'ionicons/icons';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import MeTabPage from './pages/me/MeTab';
import ExperiencesTabPage from './pages/experiences/ExperiencesTab';
import EducationTabPage from './pages/education/EducationTab';
import MovieList from './components/MovieList';
import { useTranslation } from 'react-i18next';
import Details from './components/Details';
import WhereToFindMe from './components/WhereToFindMe';
import SkillsTabPage from './pages/skills/SkillsTab';
import { useDispatch } from 'react-redux';
import { setAppLightMode } from './redux/actions';

const App: React.FC = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    setupConfig({
      mode: 'ios'
    });
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    prefersDark.addListener((mediaQuery) => dispatch(setAppLightMode(!mediaQuery.matches)));
    //eslint-disable-next-line
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route path="/me" component={MeTabPage} exact={true} />
            <Route path="/me/wtfm" component={WhereToFindMe} />
            <Route path="/skills" component={SkillsTabPage} exact={true} />
            <Route path="/skills/details" component={Details} />
            <Route path="/me/movies" component={MovieList} />
            <Route path="/experiences" component={ExperiencesTabPage} exact={true} />
            <Route path="/education" component={EducationTabPage} exact={true} />
            <Route exact path="/" render={() => <Redirect to="/me" />} />
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="me" href="/me">
              <IonIcon icon={personOutline} />
              <IonLabel>{t("meTab")}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="skills" href="/skills">
              <IonIcon icon={laptopOutline} />
              <IonLabel>{t("skillsTab")}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="experiences" href="/experiences">
              <IonIcon icon={gitBranchOutline} />
              <IonLabel>{t("experiencesTab")}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="education" href="/education">
              <IonIcon icon={schoolOutline} />
              <IonLabel>{t("educationTab")}</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
}

export default App;
