import React, { useEffect } from 'react';
import { IonButtons, IonToggle } from '@ionic/react';
import { useSelector, useDispatch } from 'react-redux';
import { setAppLightMode } from './redux/actions';

const ModeSelector: React.SFC = () => {

    let lightMode: boolean = useSelector((state: any) => state.lightMode);
    const dispatch = useDispatch();

    const handleChangeMode = (toggled: boolean) => {
        lightMode = toggled
        dispatch(setAppLightMode(lightMode))
        localStorage.setItem("lightMode", String(lightMode));
        document.body.classList.toggle('dark', !lightMode);
    }

    useEffect(() => {
        if (localStorage.getItem("lightMode") !== null) {
            handleChangeMode(localStorage.getItem("lightMode")! === "true")
        }
        //eslint-disable-next-line
      }, []);

    return (
      <IonButtons slot="start">
        <IonToggle color="warning" checked={lightMode} onIonChange={e => handleChangeMode(e.detail.checked)} />
      </IonButtons>
    );
};

export default ModeSelector;
