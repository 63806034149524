const defaultState = {
    language: "fr",
    lightMode: true
}

export default function reducer(state = defaultState, { type, payload }: { type: string, payload: any }): any {
    switch (type) {
        case 'SET_APP_LANGUAGE_STATE':
            return {
                ...state,
                language: payload
            }
        case 'SET_APP_LIGHT_MODE_STATE':
            return {
                ...state,
                lightMode: payload
            }
    }
    return state
}