import React, { useEffect } from 'react';
import { IonSelect, IonSelectOption } from '@ionic/react';
import { useSelector, useDispatch } from 'react-redux';
import { setAppLanguage } from './redux/actions';
import i18n from './i18n';

const LanguageSelector: React.SFC = () => {

    const language = useSelector((state: any) => state.language);
    const dispatch = useDispatch();

    const handleChangeLanguage = (language: string) => {
        dispatch(setAppLanguage(language))
        i18n.changeLanguage(language);
        localStorage.setItem("language", language);
    }

    useEffect(() => {
        if (localStorage.getItem("language") !== null) {
            handleChangeLanguage(localStorage.getItem("language")!)
        }
        //eslint-disable-next-line
      }, []);

    return (
        <IonSelect value={language} onIonChange={(e) => handleChangeLanguage(e.detail.value)} interface="popover">
            <IonSelectOption value="fr"><span role="img" aria-label="french-flag-emoji">🇫🇷</span></IonSelectOption>
            <IonSelectOption value="en"><span role="img" aria-label="uk-flag-emoji">🇬🇧</span></IonSelectOption>
        </IonSelect >
    );
};

export default LanguageSelector;
