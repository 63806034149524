import React, { useState } from 'react';
import { IonHeader, IonToolbar, IonPage, IonTitle, IonContent, IonButtons } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../LanguageSelector';
import MailFab from '../../components/MailFab';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import acsLogo from './acs-logo.png';
import meritisLogo from './meritis-logo.png';
import sopraLogo from './sopra-logo.png';
import airliquideLogo from './airliquide-logo.png';
import { goToTop } from '../../utils';
import ModeSelector from '../../ModeSelector';
import { useSelector } from 'react-redux';

const ExperiencesTabPage: React.FC = () => {
  const { t } = useTranslation();
  const [fabCssClass, setFabCssClass] = useState("extended");
  let lightMode: boolean = useSelector((state: any) => state.lightMode);

  const handleScroll = (e: any) => {
    setFabCssClass(e.detail.currentY < 50 ? "extended" : "minimized")
  }
  let white = "#ffff";
  let color = lightMode ? white : getComputedStyle(document.documentElement)
  .getPropertyValue('--ion-color-dark');
  let lightColor = lightMode ? "light" : "";
  const style = {
    contentStyle : color,
    contentArrowStyle: `7px solid ${color}`
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle><div className="clickable" onClick={() => goToTop("experiences-content")}>{t("experiencesTabPageTitle")}</div></IonTitle>
          <ModeSelector/>
          <IonButtons slot="end">
            <LanguageSelector  />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent id="experiences-content" color={lightColor} scrollEvents={true} onIonScroll={(e: any) => handleScroll(e)}>
        <MailFab cssClass={fabCssClass} />
        <VerticalTimeline>
          <VerticalTimelineElement
            contentStyle={{ background: style.contentStyle }}
            contentArrowStyle={{ borderRight: style.contentArrowStyle }}
            date={t("november") + " 2019 - " +  t("now")}
            iconStyle={{ background: white}}
            icon={<img src={acsLogo} alt="acsLogo" />}

          >
            <h3 className="vertical-timeline-element-title">{t("acs")}</h3>
            <h4 className="vertical-timeline-element-subtitle">{t("dev")}</h4>
              <p>{t("acsWorkDetailsSumUp")}</p>
              <ul>
                <li><p>{t("acsWorkDetails1")}</p></li>
                <li><p>{t("acsWorkDetails2")}</p></li>
                <li><p>{t("acsWorkDetails3")}</p></li>
                <li><p>{t("acsWorkDetails4")}</p></li>
                <li><p>{t("acsWorkDetails5")}</p></li>
                <li><p>{t("acsWorkDetails6")}</p></li>
              </ul>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            contentStyle={{ background: style.contentStyle }}
            contentArrowStyle={{ borderRight: style.contentArrowStyle }}
            date={t("january") + " 2018 - " +  t("october") + " 2019"}
            iconStyle={{ background: white}}
            icon={<img src={meritisLogo} alt="meritisLogo" />}
          >
            <h3 className="vertical-timeline-element-title">{t("meritis")}</h3>
            <h4 className="vertical-timeline-element-subtitle">{t("dev")}</h4>
            <p>
              {t("meritisBlog1")} (<a href="https://meritis.fr/techno-archi/introduction-au-progressive-web-app/" rel="noopener noreferrer" target="_blank">{t("here")}</a> {t("and")} <a href="https://meritis.fr/techno-archi/framework-ionic/" rel="noopener noreferrer" target="_blank">{t("here")}</a>).
              <br/>
              {t("meritisWorkDetailsSumUp")}
            </p>
              <ul>
                <li><p>{t("meritisWorkDetails1")}</p></li>
                <li><p>{t("meritisWorkDetails2")}</p></li>
                <li><p>{t("meritisWorkDetails3")}</p></li>
                <li><p>{t("meritisWorkDetails4")}</p></li>
                <li><p>{t("meritisWorkDetails5")}</p></li>
              </ul>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            contentStyle={{ background: style.contentStyle }}
            contentArrowStyle={{ borderRight: style.contentArrowStyle }}
            date={t("september") + " 2016 - " +  t("december") + " 2017"}
            iconStyle={{ background: white}}
            icon={<img src={sopraLogo} alt="sopraLogo" />}

          >
            <h3 className="vertical-timeline-element-title">{t("sopra")}</h3>
            <h4 className="vertical-timeline-element-subtitle">{t("dev")}</h4>
            <p>
              {t("sopraWorkDetailsSumUp")}
            </p>
              <ul>
                <li><p>{t("sopraWorkDetails1")}</p></li>
                <li><p>{t("sopraWorkDetails2")}</p></li>
                <li><p>{t("sopraWorkDetails3")}</p></li>
                <li><p>{t("sopraWorkDetails4")}</p></li>
              </ul>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            contentStyle={{ background: style.contentStyle }}
            contentArrowStyle={{ borderRight: style.contentArrowStyle }}
            date={t("september") + " 2014 - " +  t("august") + " 2016"}
            iconStyle={{ background: white}}
            icon={<img src={airliquideLogo} alt="airliquideLogo" />}

          >
            <h3 className="vertical-timeline-element-title">{t("alit")}</h3>
            <h4 className="vertical-timeline-element-subtitle">{t("applicationExpert")}</h4>
            <p>
              {t("alitWorkDetailsSumUp")}
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            contentStyle={{ background: style.contentStyle }}
            contentArrowStyle={{ borderRight: style.contentArrowStyle }}          
            date={t("september") + " 2013 - " +  t("august") + " 2014"}
            iconStyle={{ background: white}}
            icon={<img src={airliquideLogo} alt="airliquideLogo" />}

          >
            <h3 className="vertical-timeline-element-title">{t("alfi")}</h3>
            <h4 className="vertical-timeline-element-subtitle">{t("ba")}</h4>
            <p>
              {t("alfiWorkDetailsSumUp")}
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: 'rgb(16, 204, 82)', color: white }}

          />
        </VerticalTimeline>

      </IonContent>
    </IonPage>
  );
};

export default ExperiencesTabPage;
