import React, { useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonGrid, IonRow, IonCol, IonItemGroup, IonItemDivider, IonLabel, IonItem } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../LanguageSelector';
import MailFab from '../../components/MailFab';
import { Icon } from '@iconify/react';
import ubuntuIcon from '@iconify/icons-logos/ubuntu';
import debianIcon from '@iconify/icons-logos/debian';
import appleIcon from '@iconify/icons-logos/apple';
import windowsIcon from '@iconify/icons-logos/microsoft-windows';
import springIcon from '@iconify/icons-logos/spring';
import flaskIcon from '@iconify/icons-logos/flask';
import seleniumIcon from '@iconify/icons-logos/selenium';
import reactIcon from '@iconify/icons-logos/react';
import jqueryIcon from '@iconify/icons-logos/jquery';
import oracleIcon from '@iconify/icons-logos/oracle';
import mysqlIcon from '@iconify/icons-logos/mysql';
import mariadbIcon from '@iconify/icons-logos/mariadb';
import postgresqlIcon from '@iconify/icons-logos/postgresql';
import ansibleIcon from '@iconify/icons-logos/ansible';
import jenkinsIcon from '@iconify/icons-logos/jenkins';
import dockerIcon from '@iconify/icons-logos/docker';
import kubernetesIcon from '@iconify/icons-logos/kubernetes';
import { goToTop } from '../../utils';
import './SkillsTab.css';
import ModeSelector from '../../ModeSelector';


const SkillsTabPage: React.FC = () => {
  const { t } = useTranslation();
  const [fabCssClass, setFabCssClass] = useState("extended");

  const handleScroll = (e: any) => {
    setFabCssClass(e.detail.currentY < 50 ? "extended" : "minimized")
  }
  let count = 1;
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle><div className="clickable" onClick={() => goToTop("skills-content")}>{t("skillsTabPageTitle")}</div></IonTitle>
          <ModeSelector/>
          <IonButtons slot="end">
            <LanguageSelector  />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent id="skills-content" scrollEvents={true} onIonScroll={(e: any) => handleScroll(e)}>
      <MailFab cssClass={fabCssClass} />
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-lg="8" offset-lg="2" size-xl="6" offset-xl="3">
            <IonItemGroup>
                <IonItemDivider style={{"--animation-order": count++} as any}>
                  <IonLabel><strong>Java</strong></IonLabel>&nbsp;
                  {/* <Icon icon={javaIcon} /> */}
                </IonItemDivider>

                <IonItem style={{"--animation-order": count++} as any} lines="none" href="https://spring.io/" rel="noreferrer" target="_blank">
                  <Icon icon={springIcon} />&nbsp;Spring
                </IonItem>
              </IonItemGroup>

              <IonItemGroup>
                <IonItemDivider style={{"--animation-order": count++} as any}>
                  <IonLabel><strong>Python</strong></IonLabel>&nbsp;
                  {/* <Icon icon={pythonIcon} /> */}
                </IonItemDivider>

                <IonItem style={{"--animation-order": count++} as any} href="https://palletsprojects.com/p/flask/" rel="noreferrer" target="_blank">
                  <Icon icon={flaskIcon} /> &nbsp;Flask
                </IonItem>
                <IonItem style={{"--animation-order": count++} as any} lines="none" href="https://selenium.dev/" rel="noreferrer" target="_blank">
                  <Icon icon={seleniumIcon} /> &nbsp;Selenium
                </IonItem>
              </IonItemGroup>

              <IonItemGroup>
                <IonItemDivider style={{"--animation-order": count++} as any}>
                  {/* <Icon icon={javascriptIcon} />&nbsp; */}
                  <IonLabel><strong>Javascript / TypeScript</strong></IonLabel>
                </IonItemDivider>
                <IonItem style={{"--animation-order": count++} as any} href="https://reactjs.org/" rel="noreferrer" target="_blank">
                  <Icon icon={reactIcon} /> &nbsp;React
                </IonItem>
                <IonItem style={{"--animation-order": count++} as any} lines="none" href="https://jquery.com/" rel="noreferrer" target="_blank">
                  <Icon icon={jqueryIcon} />
                </IonItem>
              </IonItemGroup>

              <IonItemGroup>
                <IonItemDivider style={{"--animation-order": count++} as any}>
                  <IonLabel><strong>SQL</strong></IonLabel>
                </IonItemDivider>
                <IonItem style={{"--animation-order": count++} as any} href="https://www.oracle.com/database/" rel="noreferrer" target="_blank">
                  <Icon icon={oracleIcon} />
                </IonItem>
                <IonItem style={{"--animation-order": count++} as any} href="https://www.mysql.com/" rel="noreferrer" target="_blank">
                  <Icon icon={mysqlIcon} /> &nbsp;MySQL
                </IonItem>
                <IonItem style={{"--animation-order": count++} as any} href="https://mariadb.org/" rel="noreferrer" target="_blank">
                  <Icon icon={mariadbIcon} />
                </IonItem>
                <IonItem style={{"--animation-order": count++} as any} lines="none" href="https://www.postgresql.org/" rel="noreferrer" target="_blank">
                  <Icon icon={postgresqlIcon} /> &nbsp;PostgreSQL
                </IonItem>
              </IonItemGroup>

              <IonItemGroup>
                <IonItemDivider style={{"--animation-order": count++} as any}>
                  <IonLabel><strong>DevOps</strong></IonLabel>
                </IonItemDivider>
                <IonItem style={{"--animation-order": count++} as any} href="https://www.ansible.com/" rel="noreferrer" target="_blank">
                  <Icon icon={ansibleIcon} />&nbsp;Ansible
                </IonItem>
                <IonItem style={{"--animation-order": count++} as any} href="https://jenkins.io/" rel="noreferrer" target="_blank">
                  <Icon icon={jenkinsIcon} />&nbsp;Jenkins
                </IonItem>
                <IonItem style={{"--animation-order": count++} as any} href="https://www.docker.com/" rel="noreferrer" target="_blank">
                  <Icon icon={dockerIcon} />
                </IonItem>
                <IonItem style={{"--animation-order": count++} as any} lines="none" href="https://kubernetes.io/" rel="noreferrer" target="_blank">
                  <Icon icon={kubernetesIcon} />&nbsp;Kubernetes
                </IonItem>
              </IonItemGroup>

              <IonItemGroup>
                <IonItemDivider style={{"--animation-order": count++} as any}>
                  <IonLabel><strong>OS</strong></IonLabel>
                </IonItemDivider>

                <IonItem style={{"--animation-order": count++} as any} href="https://www.debian.org/" rel="noreferrer" target="_blank">
                  <Icon icon={debianIcon} />&nbsp;Debian
                </IonItem>
                <IonItem style={{"--animation-order": count++} as any} href="https://ubuntu.com/" rel="noreferrer" target="_blank">
                  <Icon icon={ubuntuIcon} />&nbsp;Ubuntu
                </IonItem>
                <IonItem style={{"--animation-order": count++} as any} href="https://www.apple.com/macos/what-is/" rel="noreferrer" target="_blank">
                  <Icon icon={appleIcon} />&nbsp;macOS
                </IonItem>
                <IonItem style={{"--animation-order": count++} as any} lines="none" href="https://www.microsoft.com/en-us/windows/" rel="noreferrer" target="_blank">
                  <Icon icon={windowsIcon} />&nbsp;Windows 10
                </IonItem>
                </IonItemGroup>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SkillsTabPage;